// helper function to disable submit buttons when no select option has been selected
// just add class 'empty_disable' to the select element and 'disable_button' to the input/button element
$(document).on('turbolinks:load', function() {
  if ($('.empty_disable').length > 0) {
    if ($('.empty_disable').find(":selected").val().length == 0) {
      $('.disable_button').prop('disabled', true);
    }
    $(document).on("change", '.empty_disable', function() {
      if ($(this).find(":selected").val().length != 0) {
        $('.disable_button').prop('disabled', false);
      } else {
        $('.disable_button').prop('disabled', true);
      }
    });
  }
})