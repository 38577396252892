document.addEventListener("turbolinks:load", function() {
  clearSearchInputs();
})

function clearSearchInputs(params) {
  $( "#clear-search" ).click(function() {

    $(':input','form').not(':button, :submit, :reset, :hidden').val('').removeAttr('checked').removeAttr('selected');
    $("select").each(function() {
      if ($(this)[0].selectize != undefined) {
        $(this)[0].selectize.clear();
      }
    })
    $("select#per")[0].selectize.setValue(10, true)
    $("input[value='Search']").click();
  });
}
