// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "stylesheets/application.scss"

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// After installing a 3rd party JS library through Yarn we need it to be included in webpacker
// Instructions of how to do this are dependent on each JS library and should be in their getting started docs
// Webpacker/ES6 also treats each JS file as its own scope / seperate module
// apparently this is good for de-cluttering the global scope
// this means you need to import a 3rd party library into each JS file you want to use it in
// or you can import it here and make it available in a global variable so it can be used everywhere (helpful for a library you will use everywhere like jQuery)
import jQuery from "jquery"
global.$ = jQuery;
global.jQuery = jQuery;
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("@nathanvda/cocoon")
import ClipboardJS from 'clipboard';
global.ClipboardJS = ClipboardJS;
import tippy from "tippy.js"
global.tippy = tippy;

require("javascript/clear_search")
require("javascript/password_strength")
require("javascript/burger_menu")
require("javascript/classie")
require("javascript/disable_link")
require("javascript/delete_notification")
require("javascript/file_upload")
require("javascript/selectize_dropdown")
require("javascript/sortable_rows")
require("javascript/disable_select_button")
require("javascript/dropdown_show")
require("javascript/modal")
require("javascript/advert")
require("javascript/install_button")
require("javascript/remove_attachment")
require("javascript/copy_to_clipboard")
