$(document).on('turbolinks:load', () => {
  $('#component_content_attributes_color,' +
    '#component_content_attributes_background_color,' +
    '#component_content_attributes_list_attributes_background_color,' +
    '#component_content_attributes_list_attributes_color,' +
    '#component_content_attributes_texts_attributes_0_color,' +
    '#component_content_attributes_texts_attributes_0_background_color,' +
    '#component_content_attributes_texts_attributes_1_color,' +
    '#component_content_attributes_texts_attributes_1_background_color,' +
    '#component_content_attributes_texts_attributes_2_color,' +
    '#component_content_attributes_texts_attributes_2_background_color').change(function() {
    if (this.value == 'custom_color') {
      $(`#${this.id}_picker`).show();
    } else {
      $(`#${this.id}_picker`).hide();
    }
  })
});