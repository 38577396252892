import selectize from "selectize";

document.addEventListener("turbolinks:load", function() {
  $('.selectize-multiple-dropdown').selectize({
    // to make this multiple you need to add 'multiple=""' to the select
    plugins: ['remove_button'],
  });

  $('select').not('select[class*="flatpickr"]').not('select[class*="not-alph"]').selectize({
    sortField: "text",
  });

  $('select[class*="not-alph"]').selectize({
    //dropdowns which aren't sorted by alphabetical
  });

  // this fixes an issue where the placeholder isn't full width on load/change page
  $(".selectize-input input[placeholder]").attr("style", "width: 100%;");


  // Modify dropdown styles in dropdowns to preview what they actually look like
  $(document).on("click", '.component-font-size, .selectize-input', function() {
    $('.component-font-size').each(function() {
      $(this).find('.selectize-dropdown-content').children().each(function() {
        $(this).attr('style', `font-size: ${$(this).attr('data-value')};`)
      })
    })
  })

  $(document).on("click", '.component-font-weight, .selectize-input', function() {
    $('.component-font-weight').each(function() {
      $(this).find('.selectize-dropdown-content').children().each(function() {
        $(this).attr('style', `font-weight: ${$(this).attr('data-value')};`)
      })
    })
  })

  $(document).on("click", '.component-font-style, .selectize-input', function() {
    $('.component-font-style').each(function() {
      $(this).find('.selectize-dropdown-content').children().each(function() {
        $(this).attr('style', `font-style: ${$(this).attr('data-value')};`)
      })
    })
  })

});
