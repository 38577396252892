document.addEventListener("turbolinks:load", () => {
  const addBtn = document.querySelector('.install-button');
  const installNotification = document.querySelector('.install-notification');
  const iosNotification = document.querySelector('.ios-notification');

  // Check if button is on the page
  if (addBtn != undefined) {
    // Register service worker to control making site work offline
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/serviceworker.js', { scope: '/' })
        .then(() => { console.log('Service Worker Registered (install button)'); });
    }

    // Code to handle install prompt
    let deferredPrompt;
    addBtn.style.display = 'none';
    installNotification.style.display = 'none';

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Show the button for user
      addBtn.style.display = 'block';
      installNotification.style.display = 'block';
    });

    // The prompt method must be called with user input
    addBtn.addEventListener('click', (e) => {
      // Hide button
      addBtn.style.display = 'none';
      installNotification.style.display = 'none';
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then(() => {
        deferredPrompt = null;
      });
    });

    // iOS implementation
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      iosNotification.style.display = 'block';
    }

  }
})
