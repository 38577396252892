$(document).on('turbolinks:load', function(){
  $('#user_password').on('keyup', function(){
    var pass = $("#user_password").val();
    $("#good_pass").hide();
    $("#invalid_pass").hide();

    if(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/.test(pass)){
      $("#good_pass").show();
    }else{
      $("#invalid_pass").show();
    }
  });
});
