window.recordClick = function(id) {
  $.ajax({
    url: `/homepage/record_click`,
    method: "POST",
    datatype: "json",
    data: { authenticity_token: $('[name="csrf-token"]')[0].content, advert_id: id },
    async: true,
    sucess: function(data) {}
  })
}

