$(document).on('turbolinks:load', function(){
  // this method will be a generic method for handling any modal on any page
  // it will however not be able to handle multiple modals on one page
  // all you need to do is: add the ID `modal-activator` to the element you want to activate the modal when clicked
  handleModal();
});

function handleModal() {
  $(document).on('click', "#modal-activator", function(e) {
    //activate modal
    $(".modal").addClass("is-active");
  });
  
  $(document).on('click', ".modal-background, #modal-close", function(e) {
    //close modal
    e.preventDefault();
    $(".modal").removeClass("is-active");
  });
}

