let removeManualArray = [];
let startingPositions = {};
let finalPositions = {};

$(document).on('turbolinks:load', function() {
  if ($('#sortable_rows').length > 0) {
    $('#sortable_rows').sortable({
      update: function(event, ui) {
        let index = 0;
        $('.sortable_item_position').each(function() {
          if (!$(this).hasClass("no_text")) {
            $(this).text(index + 1);
          }
          index += 1;
        });
      }
    });
    getStartingPositions();
  }
});

function getStartingPositions() {
  let index = 1;
  $('tr.sortable_row, .sortable_row').each(function() {
    startingPositions[$(this).attr('data-id')] = index;
    index += 1
  })
}

window.getFinalPositions = function() {
  let index = 1;
  $('tr.sortable_row, .sortable_row').each(function() {
    finalPositions[$(this).attr('data-id')] = index;
    index += 1
  })
  positionChanges = diff(finalPositions, startingPositions);
  $('#sortable_positions').val(JSON.stringify(positionChanges));
}

function diff(obj1, obj2) {
  final = {}
  for (let i in obj1) {
    if (obj1[i] !== obj2[i]) {
      final[i] = obj1[i]
    }
  }
  return final;
}
